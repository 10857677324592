/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "../../static/styles/main.scss"
import iconYoutube from "../../static/images/youtube.svg"
import iconFacebook from "../../static/images/facebook.svg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={"container"}>
            <div className={"row"}>
              {/* <div className={"about"}>
                <p>
                  Proteze dentare mobile este un proiect al Dr. Mihaluta Florin.
                </p>
              </div> */}
              <div className={"social"}>
                <a
                  href="https://www.youtube.com/channel/UCUyjqw9R1donlE6EiokHx8g"
                  target={"_blank"}
                  title={"Youtube"}
                  rel={"noreferrer"}
                >
                  <img alt={"Youtube"} src={iconYoutube} />
                </a>

                <a
                  href="https://www.facebook.com/MIHALUTA25"
                  target={"_blank"}
                  title={"Facebook"}
                  rel={"noreferrer"}
                >
                  <img alt={"Facebook"} src={iconFacebook} />
                </a>
              </div>
              <div className={"copyright"}>
                <p>
                  Proteze dentare mobile este un proiect al{" "}
                  <a
                    href="https://www.mihaluta.ro"
                    title={"Dr. Mihaluta Florin"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dr. Mihaluta Florin
                  </a>
                  .<br />
                  <Link to="/privacy" title={"Politica de confidențialitate"}>
                    Politica de confidențialitate
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
